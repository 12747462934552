<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1539 1.53845H15.3846V0.76922C15.3846 0.494323 15.238 0.240385 15 0.103022C14.762 -0.0343407 14.4688 -0.0343407 14.2308 0.103022C13.9928 0.240385 13.8462 0.494332 13.8462 0.76922V1.53845H10.7692V0.76922C10.7692 0.494323 10.6226 0.240385 10.3846 0.103022C10.1466 -0.0343407 9.85337 -0.0343407 9.61539 0.103022C9.37741 0.240385 9.23077 0.494332 9.23077 0.76922V1.53845H6.15385V0.76922C6.15385 0.494323 6.00722 0.240385 5.76923 0.103022C5.53125 -0.0343407 5.23798 -0.0343407 5 0.103022C4.76202 0.240385 4.61539 0.494332 4.61539 0.76922V1.53845H3.84616C2.82642 1.53965 1.84888 1.94522 1.12791 2.66636C0.40677 3.38733 0.00118681 4.36487 0 5.38461V16.1538C0.00120193 17.1736 0.406765 18.1511 1.12791 18.8721C1.84888 19.5932 2.82642 19.9988 3.84616 20H16.1539C17.1736 19.9988 18.1511 19.5932 18.8721 18.8721C19.5932 18.1511 19.9988 17.1736 20 16.1538V5.38461C19.9988 4.36487 19.5932 3.38733 18.8721 2.66636C18.1511 1.94522 17.1736 1.53964 16.1539 1.53845ZM1.53846 5.38461C1.53846 4.77248 1.78159 4.18562 2.21429 3.75274C2.64715 3.32004 3.23402 3.07691 3.84616 3.07691H4.61539V3.84614C4.61539 4.12104 4.76202 4.37498 5 4.51234C5.23799 4.64971 5.53126 4.64971 5.76923 4.51234C6.00721 4.37498 6.15385 4.12103 6.15385 3.84614V3.07691H9.23077V3.84614C9.23077 4.12104 9.37741 4.37498 9.61539 4.51234C9.85337 4.64971 10.1466 4.64971 10.3846 4.51234C10.6226 4.37498 10.7692 4.12103 10.7692 3.84614V3.07691H13.8462V3.84614C13.8462 4.12104 13.9928 4.37498 14.2308 4.51234C14.4688 4.64971 14.762 4.64971 15 4.51234C15.238 4.37498 15.3846 4.12103 15.3846 3.84614V3.07691H16.1539C16.766 3.07691 17.3528 3.32004 17.7857 3.75274C18.2184 4.1856 18.4615 4.77248 18.4615 5.38461V6.92307H1.53846V5.38461ZM18.4615 16.1538C18.4615 16.766 18.2184 17.3528 17.7857 17.7857C17.3529 18.2184 16.766 18.4615 16.1539 18.4615H3.84616C3.23402 18.4615 2.64717 18.2184 2.21429 17.7857C1.78159 17.3528 1.53846 16.766 1.53846 16.1538V8.46153H18.4615V16.1538Z" fill="#1E70BB"/>
  </svg>
</template>

<script>
export default {
  name: "IconCalendar"
}
</script>

<style scoped>

</style>