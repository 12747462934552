<template>
  <date-picker
    name="period"
    :format="format"
    value-type="format"
    placeholder="Select date"
    range
    :value="[_periodStart, _periodEnd]"
    :input-attr="{name:'period', id:'period'}"
    :clearable="true"
    :cancel="true"
    :disabled="false"
    :default-value="new Date()"
    :disabled-date="disabledDates"
    @pick="handlePick"
    @change="$emit('input', $event)"
    @click.prevent.stop.native="$emit('click')"
  >
    <template v-slot:icon-calendar>
      <IconCalendar />
    </template>
    <template v-slot:header="{ emit }">
      <span class="shortcuts__header">Shortcuts: </span>
      <button class="shortcuts__item mr-2" @click="emit(getToday())">Today</button>
      <button class="shortcuts__item mr-2" @click="emit(getYesterday())">Yesterday</button>
      <button class="shortcuts__item mr-2" @click="emit(getMTD())" v-if="_shouldMTDbeDisplayed">MTD</button>
      <button class="shortcuts__item" @click="emit(getLastMonth())" v-if="!limitDateRange">Last Month</button>
    </template>
  </date-picker>
</template>

<script>
import IconCalendar from "@/js/components/icons/IconCalendar";
import moment from "moment";
import DatePicker from "vue2-datepicker";

const updateCalendars = DatePicker.CalendarRange.methods.updateCalendars;
DatePicker.CalendarRange.methods.updateCalendars = function (...args) {
  updateCalendars.apply(this, args);
  if (args.length === 1) {
    if(moment().month() === moment(this.calendars[0]).month() ) {
      return this.calendars = [
        moment(this.calendars[0]).subtract(1, 'month').toDate(),
        moment(this.calendars[1]).subtract(1, 'month').toDate(),
      ];
    }
  }
};

export default {
  name: "VDatePicker",
  components: {
    IconCalendar,
    DatePicker
  },
  props: {
    value: Array,
    limitDateRange: {
      type: Boolean,
      default: false
    },
    amountLimitRange: {
      type: Number,
      default: 14
    }
  },
  data() {
    return {
      format: 'YYYY-MM-DD',
      selectedFromDay: null,
      selectedToDay: null,
    };
  },
  computed: {
    _periodStart() {
      return this.value[0] || moment().format('YYYY-MM-DD');
    },
    _periodEnd() {
      return this.value[1] || moment().format('YYYY-MM-DD');
    },
    _shouldMTDbeDisplayed() {
      return !this.limitDateRange || moment().date() <= 14;
    }
  },
  methods: {
    disabledDates(date) {
      if (this.limitDateRange) {
        return this.disabledDatesRange(date);
      }
      return date > new Date();
    },
    disabledDatesRange(date) {
      if (!this.selectedToDay) {
        const fromPeriod = new Date(date).setDate(new Date(date).getDate() + (this.amountLimitRange - 1));
        const toPeriod = new Date(date).setDate(new Date(date).getDate() - (this.amountLimitRange - 1));
        if (this.selectedFromDay) {
          return this.selectedFromDay > fromPeriod || this.selectedFromDay < toPeriod || date > new Date();
        }
      }
      return date > new Date();
    },
    handlePick(date) {
      if (this.limitDateRange) {
        switch (true) {
          case (!!this.selectedFromDay && !!this.selectedToDay):
            this.selectedToDay = null;
            this.selectedFromDay = new Date(date);
            break;
          case (!!this.selectedFromDay && !this.selectedToDay):
            this.selectedToDay = new Date(date);
            break;
          case !this.selectedFromDay:
            this.selectedFromDay = new Date(date);
            break;
          default:
            return;
        }
      }
    },
    getToday() {
      return [
        moment().toDate(),
        moment().toDate()
      ]
    },
    getYesterday() {

      return [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate()
      ]
    },
    getMTD() {
      return [
        moment().startOf('month').toDate(),
        moment().toDate()
      ]
    },
    getLastMonth() {
      return [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate()
      ]
    },
  },
};
</script>
<style>
.shortcuts__header {
  font-weight: bold;
  color: var(--black-main);
}
.shortcuts__item {
  color: var(--blue-main);
  text-decoration: underline;
  background: transparent;
  border: 0;
}

</style>